.input-grp{
    position: relative;
    display: flex;
    justify-content: center;
    width: 600px;
    margin: auto;
}
.converter{
    margin-top: 100px;
}

@media only screen and (max-width: 600px) {
    .input-grp{
        width: 100%;
    }
}
.carot{
    margin-left: 5px;
}
.coin-name{
    margin:0px 10px;
    font-size: 20px;
    font-weight: 600;
}
.select-coin{
    display: flex;
    align-items: center;
    border-left: 1px solid gray;
    position: absolute;
    right: 10px;
    top: 16px;
    /* padding-left: 10px; */
    cursor: pointer;
    padding: 2px;
    background: #fff;
}
.select-fromcoin
{
    display: flex;
    align-items: center;
    border-left: 1px solid gray;
    position: absolute;
    right: 10px;
    top: 16px;
    padding-left: 10px;
    cursor: pointer;
    padding: 2px;
    background: #EEEE;  
}
.swap-icon{
    text-align: center;
    margin: 10px 0px;
}
.my-form-control{
    height: 60px;
    border-radius: 15px;
    font-size: 20px;
}
.btns{
    margin-top: 20px;
}
.btn{
    margin: 10px 5px;
}
.ddl-coin{
    border: none;
}
.coin-icon{
    margin: 0px 4px;
}