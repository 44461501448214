.PerpetualChart {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .chart-container {
    flex: 1;
    position: relative;
  }
  .legend {
    position: absolute;
    left: 12px;
    top: 12px;
    z-index: 1;
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
    color: #ccc;
  }
  .value{
    color: darkturquoise;
  }
  .TVChartContainer {
    /* height: calc(100vh - 300px); */
    height: 500px;
  }