// @import '~bootstrap/scss/bootstrap';
.content{
    margin: 20px;
    border-radius: 16px;
    background: #fff;
    padding: 16px;
    overflow: auto;
}
.btn-solid{
    padding: 8px 12px;;
}
.btn-solid:hover{
    opacity: 0.9;
}
.btn-md{
    font-size: 12px;
    font-weight: 600;
}

.bg-green-5{
    background: #38A169;
}
.bg-green-w-5{
    background: #22c35e;
}
.bg-yellow-5{
    background: #d49b37
}
.bg-dark{
    background: #333;
}
.bg-black{
    background: #111;
}
.bg-white{
    background: #fff;
}
.bg-off-white{
    background: #e0d4d461;
}
.bg-red{
    background: #E53E3E;
}
.bg-light-gray{
    background: #e7e7e7;
}

.txt-yellow-5{
    color: #d49b37
}
.txt-red{
    color: #E53E3E;
}
.txt-green{
    color: #38A169;
}
.text-lrg{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0.75rem;
}
.text-md{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.75rem;
}
.xs-text{
    font-size: 13px;
    font-weight: 300;
}
.font-16{
    font-size: 16px;
}
.font-12{
    font-size: 12px;
}
.txt-light-gray{
    color: rgb(221, 221, 221);
}
.txt-black{
    color: #333;
}

.txt-white{
    color: #fff
}


.p-30{
    padding: 48px;
}
.px-4{
    padding-left: 24px;
    padding-right: 24px;
}
.px-2{
    padding-left: 16px;
    padding-right: 16px;
}
.py-2{
    padding-top: 8px;
    padding-bottom: 8px;
}
.p-2{
    padding: 8px;
}
.p-4{
    padding: 16px;
}

.mt-1{
    margin-top: 2px;
}
.m-1{
    margin: 6px;
}
.ml-2{
    margin-left: 8px;
}
.ml-4{
    margin-left: 16px;
}
.mr-4{
    margin-right: 16px;
}
.mr-2{
    margin-right: 8px;
}
.mt-4{
    margin-top: 16px;
}
.mt-3{
    margin-top: 12px;
}
.mt-6{
    margin-top: 48px;
}
.mb-1{
margin-bottom: 4px;
}
.d-flex{
    display: flex;
    &.flex-end{
        justify-content: end;
        align-items: center;
    }
    &.flex-center{
        justify-content: center;
        align-items: center;
    }
    &.flex-start{
        justify-content: start;
        align-items: center;
    }
    &.flex-between{
        justify-content: space-between;
        align-items: center;
    }
}
.cursor-pointer{
 cursor: pointer;
}
.rounded-3{
    border-radius: 8px;
}

button[disabled]{
    opacity: 0.5;
}
.error-text{
    font-size: 11px;
    color: rgb(246, 70, 93);
}


.rounded-sm{
    border-radius: 8px;
}
.rounded-md{
    border-radius: 16px;
}
.border-none{
    border: none;
}
.font-w-600{
    font-weight: 600
}
.font-w-500{
    font-weight: 500
}
.badges{
    padding: 2px 4px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 11px;
    &.badges-yellow{
        background: #FEFCBF;
        color: #d49b37;
        }
    &.badges-green{
        background: #C6F6D5;
        color: #22543d;
        }
    &.badges-blue{
        background: #bee3f8;
        color: #2a4365;
        }
        &.badges-red{
            background: rgb(250, 194, 194);
            color: red;
            }
}
.w-100{
    width: 100%;
}
.w-50{
    width: 50%;
}







.grp-btn-primary{
    padding: 8px 12px;
    font-size: 16px;
    font-weight: 600;
    color: #2C7A7B;
    margin-right: 1px;
    background: none;
    border: none;
    border-radius: 5px;
    &.active{
        background: #F6E05E !important;
        color: #333;
        :hover{
            background: #d49b37;
        }
    }
   
}
.grp-btn-primary:hover, .grp-btn-sec:hover{
    background: #E6FFFA;
}

.grp-btn-sec{
    padding: 8px 12px;
    font-size: 16px;
    font-weight: 600;
    color: #2C7A7B;
    margin-right: 1px;
    background: none;
    border: none;
    border-radius: 5px;
    &.active{
        background: #2b6cb0 !important;
        color: #FFF;
        :hover{
            background: #134479;
        }
    }
    :hover{
        background: #E6FFFA;
    }
}

.buy-sell-btn{
background: #FFF;
border: 1px solid #E2E8F0;
padding: 8px 16px;
border-radius: 5px;
font-size: 16px;
font-weight: 600;
    &.buy{
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        &.active{
            background: #38A169;
            color: #fff;
            border: 1px solid 38A169;
        }
    }
    &.sell{
        border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    &.active{
        background: #E53E3E;
        color: #fff;
        border: 1px solid #E53E3E;
    }
    }
}
.buy-sell-btn:hover{
    background: #EDF2F7;
    border: 1px solid #E2E8F0;
}

table{
    th{
        color: #4A5568;
        font-size: 12px;
        font-weight: 600;
    }
    td{
    padding: 20px 8px !important;
    }
}
.avatar{
    border-radius: 50%;
    background: peru;
    height: 40px;
    width: 40px;
    font-weight: 600;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 1px solid #ddd;
}

//transfer popup
.from-to{
    color: #6c6e70;
    font-weight: 600;
    width: 75px;
}
.divider{
    // width: 100%;
    border-bottom: 1px solid rgb(210 207 204);
    // margin: 8px 0px;
}
.form-input{
    padding: 8px 12px ;
    border: 1px solid lightgray;
    border-radius: 10px;
    input{
        border: none;
        width: 70%;
        ::placeholder{
            color: #ddd;
            font-weight: 500;
        }
    }
    .label{
        font-size: 12px;
        color: #6c6e70;
        font-weight: 400;
        padding: 0;
    }
    .all{
        color: #d49b37;
        font-weight: 600 ;
        cursor: pointer;
        font-size: 14px;
    }
    .coin-icon{
        border-radius: 50%;
        margin: 0;
    }
    .coin-name{
        font-size: 14px;
        font-weight: 600;
        color: #2C7A7B;

    }
}
.coin_list .coin_info {
    margin-left: 18px;
    font-size: 12px;
    line-height: 19px;
    width: 100%;
}
.coin_list .coin_bx {
    display: inline-flex;
    width: 100%;
}
.coin_list .coin_bx strong {
    font-weight: 600;
}
.coin_list .coin_bx span{float: right;}
.coin_list .outer_bx {
    border: 1px solid #e3ebf5;
    border-radius: 10px;
    padding: 20px 20px 15px;
    background: #f6feff;
    margin: 15px 8px;
}
.coin_list .coin_info h6 {
    font-size: 15px;
    text-transform: none;
    margin: 0 0 7px;
}
.coin_list hr {
    margin-top: 8px;
    margin-bottom: 8px;
}

.coin_list .coin_info {
    margin-left: 10px;
}

.coin_list .outer_bx {
    padding: 17px 14px 15px;
}
.coin_list .coin_bx img {
    width: 35px;
    height: 35px;
    background: aquamarine;
    border-radius: 50%;
    padding: 3px;
}
.coin_list .outer_bx {
    padding: 20px 20px 15px;
}
.coin_list .coin_info {
    margin-left: 20px;
}
.est-val{
    display: inline-block;
    margin: 20px;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 16px;
}


element.style {
    background: lightgray;
}
.balance_tab_list .nav-tabs>li.active>a{
    background: #faf8f8 !important;
}
.tab-content{
    background: #faf8f8;
    padding: 16px;
}.ava
.pm-bg{
        border-radius: 16px;
        background: #fff;
        padding: 16px;
        margin-top: 5px;
}
.pm-bg-1{
    background: #fffaf4;
    padding: 16px;
    border-radius: 10px;
    margin-bottom: 16px;
}