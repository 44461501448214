#chat2 .form-control {
    border-color: transparent;
  }
  
  #chat2 .form-control:focus {
    border-color: transparent;
    box-shadow: inset 0px 0px 0px 1px transparent;
  }
  
  .dividers:after,
  .dividers:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
    border-bottom:none !important ;
  }