.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mx-2{
margin-left: 2px !important;
margin-right: 2px !important;
}
.modal-backdrop {
  background-color: #00000042 !important;
}
.mt-4{
  margin-top: 2rem;
}
#hv-cameraholder{
  transform: scaleX(1) !important;
}
.hv-loader-box .hv-loader, :not(svg) {
  transform-origin: center !important;
}
.active .tab-title {
  color: #f0ad4e;
  border-color: #f0ad4e;
}