.css-1f9551p {
    box-sizing: border-box;
    min-width: 0px;
    display: inline-block;
    position: relative;
    margin: 0px;
}

.css-stlzer {
    box-sizing: border-box;
    min-width: 0px;
    font-size: 24px;
    fill: currentcolor;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.4);
    transform: scaleY(1.3) rotateY(0deg);
    width: 1em;
    height: 1em;
    margin: 0px;
}

path {
    d: path("M 21 15.997 H 3 v 1.25 h 18 v -0.625 v -0.625 Z M 3 12.262 h 12 v -0.625 v -0.625 H 3 v 1.25 Z M 3 7.25 h 18 v -0.625 V 6 H 3 v 1.25 Z m 13.88 7.504 l 4.062 -3.125 l -4.063 -3.125 v 6.25 Z");
    fill: currentcolor;
}